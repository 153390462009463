<template>
  <div
    id="app"
    class="min-vh-100 d-flex flex-column justify-content-between"
    :class="`wait-for-${waitFor}`"
    @mousemove="handleUserActivity"
  >
    <b-container
      fluid
      v-if="updateNoteVisible && !isLoadedInIframe"
      :class="{ 'position-absolute': !navigationVisible, 'bg-sand-3': navigationVisible }"
    >
      <b-row>
        <b-col class="p-0 d-flex">
          <b-alert :show="updateNoteVisible" variant="warning" class="m-0 mx-auto py-0 px-2">
            <div class="d-flex justify-content-between">
              <span>{{ $t("header.updateNote") }} <a href="https://senas.sblizingas.lt">senas.sblizingas.lt</a></span>
              <b-button variant="link" class="p-0 ml-2 d-flex" @click="updateNoteDismissed">
                <b-icon icon="x" class="my-auto close"></b-icon>
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <template v-if="navigationVisible">
      <b-navbar toggleable="lg" class="bg-sand-3 text-white d-lg-none">
        <b-container fluid="md">
          <b-navbar-brand :to="routerPaths.ROOT">
            <img :data-version="appReleaseForTracking" class="logo img-responsive" src="@/assets/logo.svg" />
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" class="bg-sand-4 text-white mt-1"></b-navbar-toggle>

          <div id="mobile-contacts" class="w-100 justify-content-between d-flex pt-2">
            <service-statuses v-if="isManager" :services="services" />
            <template v-else>
              <div class="my-auto">
                <a :href="`tel:${helpPhone}`" class="text-white pr-3 text-nowrap"><b-icon-telephone class="mr-1" />{{ helpPhone }}</a>
                <a :href="`mailto:${managerEmail}`" class="text-white pr-3 text-nowrap"
                  ><b-icon-envelope class="mr-1" />{{ managerEmail }}</a
                >
              </div>
              <div class="my-auto text-right">
                <span class="d-inline-block pl-3 text-nowrap">{{ workingHoursWorkdays }}</span>
                <span class="d-inline-block pl-3 text-nowrap">{{ workingHoursWeekends }}</span>
                <span class="d-inline-block pl-3 text-nowrap">{{ workingHoursSundays }}</span>
              </div>
            </template>
          </div>

          <b-collapse id="nav-collapse" class="mt-3" is-nav>
            <b-nav-item
              :to="routerPaths.NEW_APPLICATION"
              @click="storeApplicationPendingState(null)"
              active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("newApplication.title") | capitalize }}
            </b-nav-item>
            <b-nav-item
              :to="routerPaths.APPLICATIONS"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
              @click="resetApplicationsFilters()"
            >
              {{ $t("applications.title") | capitalize }}
            </b-nav-item>
            <b-nav-item
              :to="routerPaths.SPREADSHEET"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("spreadsheet.title") | capitalize }}
            </b-nav-item>
            <b-nav-item
              :to="routerPaths.NEWS"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("news.title") | capitalize }}
            </b-nav-item>
            <b-nav-item
              :to="routerPaths.REPORTS"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("meta.titles.reports") | capitalize }}
            </b-nav-item>
            <b-nav-item
              v-if="user().MatoIeskoti == 1"
              :to="routerPaths.SEARCH_APPLICATIONS"
              @click="clearSearch"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("searchApplications.title") | capitalize }}
            </b-nav-item>
            <b-nav-item
              v-if="isSupervisor"
              :to="routerPaths.ACCOUNTS_LIST"
              exact
              exact-active-class="active bg-white no-hover"
              link-classes="color-gray-5 bg-sand-2 hover-bg-sand-1 hover-border-sand-1"
            >
              {{ $t("meta.titles.accounts") | capitalize }}
            </b-nav-item>
            <div class="color-gray-5 bg-sand-2 px-3 hover-bg-sand-1 no-hover d-flex justify-content-between pb-2">
              <div v-if="user()" class="mt-1">
                <span class="username">{{ user().VardasPavarde }}</span
                >, (<router-link
                  variant="link"
                  v-if="!isManager"
                  :to="routerPaths.ACCOUNT_SETTINGS"
                  class="text-underline"
                  v-b-toggle.nav-collapse
                  ><span class="userlogin">{{ user().userName }}</span></router-link
                ><span class="userlogin" v-else>{{ user().userName }}</span
                >)
              </div>

              <div class="mt-auto text-nowrap d-flex">
                <searchable-select
                  class="w-auto"
                  toggle-class="
                    bg-sand hover-bg-sand-4 border-2 text-white font-weight-bold mr-2 font-15px d-flex align-items-center"
                  drop-down-class="no-min-width"
                  size="sm"
                  toggle-default-border-class="border-sand-4"
                  :options="languageOptions"
                  v-model="selectedLocale"
                  @input="changeLocale($event)"
                  value-field="locale"
                  text-field="text"
                  no-blank-option
                  no-filter
                  no-filter-input
                />

                <b-button
                  variant="sand"
                  size="sm"
                  class="border-2 text-danger"
                  @click.prevent="logOutAndRedirect()"
                  :title="$t('login.logOut') | capitalize"
                >
                  <b-icon-power />
                </b-button>
              </div>
            </div>
          </b-collapse>
        </b-container>
      </b-navbar>

      <header class="bg-sand-3 d-none d-lg-block">
        <b-container>
          <b-row>
            <b-col class="text-white d-flex justify-content-between pt-3">
              <div class="d-flex">
                <router-link :to="routerPaths.ROOT">
                  <img :data-version="appReleaseForTracking" class="logo img-responsive" src="@/assets/logo.svg" />
                </router-link>
                <service-statuses v-if="isManager" :services="services" />
                <template v-else>
                  <div class="my-auto pl-4">
                    <a :href="`tel:${helpPhone}`" class="d-block text-white"><b-icon-telephone class="mr-1" />{{ helpPhone }}</a>
                    <a :href="`mailto:${managerEmail}`" class="d-block text-white"><b-icon-envelope class="mr-1" />{{ managerEmail }}</a>
                  </div>
                  <div class="my-auto pl-4">
                    <div>{{ workingHoursWorkdays }}</div>
                    <div>
                      {{ workingHoursWeekends }}<span class="pl-2">{{ workingHoursSundays }}</span>
                    </div>
                  </div>
                </template>
              </div>
              <div class="d-flex">
                <div class="text-right mt-auto">
                  <div>
                    <template v-if="user">
                      <span class="username">{{ user().VardasPavarde }}</span
                      >, (<router-link v-if="!isManager" :to="routerPaths.ACCOUNT_SETTINGS" class="text-white text-underline">
                        <span class="userlogin">{{ user().userName }}</span></router-link
                      ><span class="userlogin" v-else>{{ user().userName }}</span
                      >)
                    </template>

                    <div class="d-flex justify-content-end pt-1">
                      <zoom-ui />
                      <searchable-select
                        class="w-auto"
                        toggle-class="
                          bg-sand hover-bg-sand-4 border-2 text-white font-weight-bold mr-2 font-15px btn-sm d-flex align-items-center"
                        drop-down-class="no-min-width"
                        toggle-default-border-class="border-sand-4"
                        :options="languageOptions"
                        v-model="selectedLocale"
                        @input="changeLocale($event)"
                        value-field="locale"
                        text-field="text"
                        no-blank-option
                        no-filter
                        no-filter-input
                      />
                      <b-button
                        variant="sand"
                        size="sm"
                        class="text-danger d-flex align-items-center border-2 border-sand-4"
                        @click.prevent="logOutAndRedirect()"
                        :title="$t('login.logOut') | capitalize"
                      >
                        <b-icon-power />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <!-- top navigation -->
              <b-nav tabs class="border-bottom-sand font-morebi">
                <b-nav-item
                  :to="routerPaths.NEW_APPLICATION"
                  @click="storeApplicationPendingState(null)"
                  active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("newApplication.title") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  :to="routerPaths.APPLICATIONS"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                  @click="resetApplicationsFilters()"
                >
                  {{ $t("applications.title") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  :to="routerPaths.SPREADSHEET"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("spreadsheet.title") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  :to="routerPaths.NEWS"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("news.title") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  :to="routerPaths.REPORTS"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("meta.titles.reports") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  v-if="user().MatoIeskoti == 1"
                  :to="routerPaths.SEARCH_APPLICATIONS"
                  @click="clearSearch"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("searchApplications.title") | capitalize }}
                </b-nav-item>
                <b-nav-item
                  v-if="isSupervisor"
                  :to="routerPaths.ACCOUNTS_LIST"
                  exact
                  exact-active-class="active bg-white no-hover"
                  link-classes="color-gray-5 bg-sand-2 py-1 hover-bg-sand-1 hover-border-sand-1"
                >
                  {{ $t("meta.titles.accounts") | capitalize }}
                </b-nav-item>
              </b-nav>
              <!-- / top navigation -->
            </b-col>
          </b-row>
        </b-container>
      </header>
    </template>

    <notifications-display v-if="isLoggedIn" :hideTypes="hideNotificationsByRoute" />

    <router-view v-if="!isLoadedInIframe" :class="{ 'mb-auto my-2': navigationVisible }" />

    <iframe-login v-else></iframe-login>

    <b-toast
      v-show="!isLoadedInIframe"
      id="sw-toast"
      :title="$t('serviceWorker.updated') | capitalize"
      variant="success"
      no-auto-hide
      v-model="updateExists"
      toaster="footerToaster"
    >
      {{ $t("serviceWorker.reloadMessage") }}
    </b-toast>

    <footer v-if="navigationVisible" class="bg-red-3 text-white p-3 mt-auto">
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-between">
            <span>{{ $t("footer.rightsReserved") }}</span>
            <span>
              {{ appEnv }} <span class="appversion">{{ version }}</span>
            </span>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { mapActions, mapGetters } from "vuex";
import { BIconEnvelope, BIconTelephone, BIconPower } from "bootstrap-vue";
import NotificationsDisplay from "@/components/notificationsDisplay.vue";
import { Validator } from "vee-validate";
import { combineExpiredSessionRoutes, routerPaths, RoutesWithoutMenu } from "@/router/routerPaths";
import swUpdate from "@/mixins/swUpdate";
import releaseVersion from "@/mixins/releaseVersion";
import router from "@/router";
import TimeRequests from "@/mixins/timeRequests";
import { GlobalStorageKeys, ServiceInterface, SystemDefaults } from "./interfaces/applicationSettings";
import ZoomUi from "@/components/ZoomUi.vue";
import ServiceStatuses from "@/components/serviceStatuses.vue";
import soapRequests from "./mixins/soapRequests";
import IframeLogin from "@/views/IframeLogin.vue";
import SearchableSelect from "./components/searchableSelect.vue";
import EventBus from "@/lib/EventBus";

export default mixins(swUpdate, TimeRequests, releaseVersion, soapRequests).extend({
  name: "App",
  components: {
    BIconEnvelope,
    BIconTelephone,
    BIconPower,
    NotificationsDisplay,
    ZoomUi,
    ServiceStatuses,
    IframeLogin,
    SearchableSelect,
  },
  created() {
    // this will allow to monitor session related changes on another tab
    window.addEventListener("storage", this.onStorageUpdate);

    if (this.inIframe) {
      this.isLoadedInIframe = true;
    }
  },
  mounted() {
    this.selectedLocale = this.locale;
    if (process.env.VUE_APP_UPDATE_NOTE_VISIBLE !== "0") {
      this.updateNoteVisible = true;

      const updateNoteStored = this.$ls.get(GlobalStorageKeys.UPDATE_NOTE_VISIBLE);
      if (updateNoteStored !== null) {
        this.updateNoteVisible = updateNoteStored;
      } else {
        this.$ls.set(GlobalStorageKeys.UPDATE_NOTE_VISIBLE, this.updateNoteVisible);
      }
    } else {
      this.$ls.remove(GlobalStorageKeys.UPDATE_NOTE_VISIBLE);
    }

    this.helpPhone = process.env.VUE_APP_HELP_INFO_PHONE || "";
    this.managerEmail = process.env.VUE_APP_MANAGER_EMAIL_ADDRESS || "";
    this.workingHoursWeekends = process.env.VUE_APP_WORKING_HOURS_WEEKENDS || "";
    this.workingHoursSundays = process.env.VUE_APP_WORKING_HOURS_SUNDAYS || "";
    this.workingHoursWorkdays = process.env.VUE_APP_WORKING_HOURS_WORKDAYS || "";
    this.refreshServicesEvery = +(process.env.VUE_APP_SERVICE_STATUSES_CHECK_INTERVAL || 18000);
    this.mounted = true;
    this.monitorSessionTimeout();
  },
  data() {
    return {
      isLoadedInIframe: false,
      routerPaths,
      helpPhone: "",
      managerEmail: "",
      workingHoursWeekends: "",
      workingHoursSundays: "",
      workingHoursWorkdays: "",
      selectedLocale: "lt",
      languageOptions: [
        { id: 1, text: "LT", locale: "lt" },
        { id: 2, text: "EN", locale: "en" },
      ],
      sessionExtendTimeout: undefined as number | any,
      sessionExpirationInterval: undefined as number | any,
      mounted: false,
      services: [] as ServiceInterface[],
      refreshServiceStatusesInterval: null as any,
      refreshServicesEvery: 180000,
      updateNoteVisible: false,
      waitFor: 0,
    };
  },
  methods: {
    ...mapActions({
      logOut: "auth/setUserLoggedOut",
      storeLocale: "auth/storeLocale",
      extendSession: "auth/extendSession",
      storeApplicationPendingState: "newApplication/storePendingState",
      showSessionExpiredError: "notifications/showSessionExpiredError",
      setSessionTimeout: "auth/setSessionTimeout",
      setSessionExpired: "auth/setSessionExpired",
      clearNotifications: "notifications/dropAll",
      set2FaVerification: "auth/set2FaVerification",
      initializeUserStorage: "auth/initialize",
      set2FaVerificationPending: "auth/set2FaVerificationPending",
    }),
    logOutAndRedirect() {
      clearInterval(this.sessionExpirationInterval);
      clearTimeout(this.sessionExtendTimeout);
      clearInterval(this.refreshServiceStatusesInterval);
      this.set2FaVerificationPending(false);
      this.$ls.remove(GlobalStorageKeys.PENDING_2FA_VERIFICATION);
      this.logOut().finally(() => {
        this.changeLocale("lt");
      });
    },
    changeLocale(event: string) {
      this.storeLocale(event);
      this.$root.$i18n.locale = event;
      this.$nextTick(() => Validator.localize(`${event}`, [event]));

      // translate current document title
      document.title = this.$t(this.$route.meta ? this.$route.meta.title : "").toString();
      document.documentElement.setAttribute("lang", event);
    },
    handleUserActivity() {
      if (!this.isLoggedIn || this.isManager || !this.mounted) {
        return;
      }

      clearTimeout(this.sessionExtendTimeout);

      if (this.isSessionExpired) {
        return;
      }

      this.sessionExtendTimeout = setTimeout(() => {
        this.checkSessionExpired(true)
          .then((result) => {
            if (result) {
              this.showSessionExpiredError(this.sessionTimeout);
              this.redirectOnSessionTimeout();
            }

            // this will restart session check interval
            this.monitorSessionTimeout();
          })
          .catch((error) => {
            console.log("Time request error:", error.message);
          });
      }, SystemDefaults.SESSION_CHECKING_TIMEOUT_MS);
    },
    redirectOnSessionTimeout() {
      if (this.isRouteWithExpiredSession && ![routerPaths.NEWS, routerPaths.ROOT].includes(router.currentRoute.path as routerPaths)) {
        router.push({ path: routerPaths.NEWS });
      }
    },
    monitorSessionTimeout() {
      if (!this.isLoggedIn || this.isManager || !this.mounted) {
        return;
      }

      clearInterval(this.sessionExpirationInterval);

      this.sessionExpirationInterval = setInterval(() => {
        if (this.isSessionExpired) {
          this.showSessionExpiredError(this.sessionTimeout);
          this.redirectOnSessionTimeout();

          clearInterval(this.sessionExpirationInterval);

          return;
        }

        this.checkSessionExpired()
          .then((result) => {
            if (result) {
              this.showSessionExpiredError(this.sessionTimeout);
              this.redirectOnSessionTimeout();

              clearInterval(this.sessionExpirationInterval);
            }
          })
          .catch((error) => {
            console.log("Time request error:", error.message);
          });
      }, (this.sessionTimeout + 10) / 2); // <- hit interval right after sessionTimeout happens
    },
    onStorageUpdate(storageEvent: StorageEvent) {
      if (!this.isInitialized) {
        return;
      }

      let deviceKey = "";
      if (this.is2FaEnabled && this.isLoggedIn) {
        // here we need to make sure, that 2fa state is correctly synced between different browser tabs
        // if in one place user performs 2fa verification and succeeds then in other tabs we should not ask about it
        // and if in one tab 2fa starts asking again the same should be reflected in another tabs
        // this happens because of fact, that we do not check constantly if info in LS remains accurate,
        // but we do that if user would open new tab
        deviceKey = `${GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE}-${this.user().userName}`;
        // const newData = this.$ls.get(GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE, { encrypt: false });
        const newData = this.$ls.get(deviceKey);

        if (storageEvent.key === deviceKey) {
          if ((!newData && this.is2FaVerified) || (newData && !this.is2FaVerified)) {
            window.location.reload();
          }
        }
      }

      switch (storageEvent.key) {
        case GlobalStorageKeys.SESSION_EXPIRES:
          this.monitorSessionTimeout();
          this.extendSession(this.$ls.get(GlobalStorageKeys.SESSION_EXPIRES, { encrypt: false }));
          break;
        case GlobalStorageKeys.APPLICATION_USER:
          if (!storageEvent.newValue) {
            this.logOutAndRedirect();
          } else {
            if (!this.isLoggedIn && this.$router.currentRoute.path === routerPaths.LOGIN) {
              // if we are in login screen and user signs in in another tab - it is safe to reload page and login in this tab too
              window.location.reload();
            }
          }
          EventBus.$emit("application-user-changed");
          break;
        case deviceKey:
          // this will be triggered if user would decide to remove LS entry
          if (!storageEvent.newValue) {
            this.logOutAndRedirect();
          }
          break;
        case GlobalStorageKeys.PENDING_2FA_VERIFICATION:
          this.set2FaVerificationPending(storageEvent.newValue);
          break;
      }
    },
    checkSessionExpired(extend = false) {
      return new Promise((resolve, reject) => {
        if (this.isLoggedIn && !this.isManager && !this.isSessionExpired) {
          this.timeNow()
            .then((res) => {
              if (this.sessionExpires <= +res) {
                this.setSessionExpired(true);
                resolve(true);
              }

              if (extend) {
                this.extendSession(+res + this.sessionTimeout);
              }

              resolve(false);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    resetApplicationsFilters() {
      sessionStorage.removeItem(GlobalStorageKeys.APPLICATION_FILTER_PARAMS);
    },
    clearSearch() {
      this.clearNotifications();
      EventBus.$emit("clear-search");
    },
    refreshServiceStatuses() {
      if (!this.isLoggedIn || !this.isManager) {
        return;
      }

      // initial load
      this.requestServiceStatuses();

      // poll for updates in interval
      this.refreshServiceStatusesInterval = setInterval(() => {
        this.requestServiceStatuses();
      }, this.refreshServicesEvery);
    },
    requestServiceStatuses() {
      this.soapServisuStatusas().then((response: any) => {
        if (response?.Sistemos.Sistema) {
          const systems: ServiceInterface[] = [];
          response?.Sistemos.Sistema.forEach((s: ServiceInterface) => {
            systems.push(s);
          });

          this.services = systems;
        }
      });
    },
    updateNoteDismissed() {
      this.updateNoteVisible = false;
      this.$ls.set(GlobalStorageKeys.UPDATE_NOTE_VISIBLE, this.updateNoteVisible);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      locale: "auth/locale",
      isSupervisor: "auth/isSupervisor",
      sessionExpires: "auth/sessionExpires",
      isSessionExpired: "auth/isSessionExpired",
      sessionTimeout: "auth/sessionTimeout",
      applicationsInSession: "auth/applicationsInSession",
      is2FaEnabled: "auth/is2FaEnabled",
      is2FaVerified: "auth/is2FaVerified",
      additionalInfo: "auth/additionalInfo",
      isLimitedLogin: "auth/isLimitedLogin",
      isManager: "auth/isManager",
      isInitialized: "auth/isInitialized",
    }),
    isRouteWithoutMenu(): boolean {
      return (RoutesWithoutMenu.find((item) => item === this.$route.name) || []).length > 0 || false;
    },
    navigationVisible(): boolean {
      if (this.isRouteWithoutMenu) {
        // hide based on route without menu match only
        return false;
      }

      // hide menu based on 2fa verification
      return this.isLoggedIn && (!this.is2FaEnabled || (this.is2FaEnabled && this.is2FaVerified));
    },
    isRouteWithExpiredSession(): boolean {
      return !(
        !combineExpiredSessionRoutes(this.applicationsInSession || 0).includes(this.$route.path as routerPaths) &&
        !combineExpiredSessionRoutes(this.applicationsInSession || 0).includes(this.$route.name as routerPaths)
      );
    },
    hideNotificationsByRoute(): string[] {
      // in some specific routes we do not need to show notification about expired session
      // because for example route doesn't have menu and session is not required, like recognizer route
      return this.isRouteWithExpiredSession && this.isRouteWithoutMenu ? ["sessionExpired"] : [];
    },
  },
  watch: {
    $route: function (val) {
      // if app was updated
      // this will initiate app reload after navigating to another route
      this.refreshApp();
      // grab meta title from route config, translate it and put on document title
      document.title = this.$t(val.meta.title)?.toString() || "SB Lizingas LPS";
    },
    isRouteWithoutMenu: function (val) {
      // this will prevent screen from blinking when user should not see navigation menu
      if (val) {
        document.body.classList.add("bg-gray-0");
      } else {
        document.body.classList.remove("bg-gray-0");
      }
    },
    isLoggedIn: function (val) {
      if (!val) {
        return;
      }

      this.refreshServiceStatuses();
    },
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
});
</script>

<style lang="scss" scoped>
header {
  background: #ceba7c;
  background: radial-gradient(at 35% 0%, #dccea1 1%, rgba(206, 186, 124, 0.5) 30%), linear-gradient(to bottom, #ceba7c 30%, #9f8f5e 100%);
}
</style>

<style>
#sw-toast {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.navbar-toggler {
  background-color: red;
}

#nav-collapse,
.list-style-none {
  list-style: none;
}
</style>
